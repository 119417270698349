<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item active">
        <a href="javascript:;">Home</a>
      </li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">
      Users
      <small>ผู้ใช้งานระบบ</small>
    </h1>
    <!-- end page-header -->

    <!-- ###################################################################################### -->
    <panel title="ผู้ใช้งานระบบ" bodyClass="p-0">
      <b-table responsive striped hover :items="users"></b-table>
    </panel>
  </div>
</template>

<script>
// import _ from 'lodash'

export default {
  name: 'UserElec',
  data() {
    return {
      passwd: {},
      users: [],
    }
  },
  mounted() {
    this.getRecords()
  },
  methods: {
    getRecords() {
      return this.axios.get(`admin/users`).then((response) => {
        this.users = response.data
      })
    },
  },
}
</script>

<style></style>
